<template>
    <div class="driver-withdrawal-detail">
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <div class="driver-withdrawal-content" ref="printContent" id="printView">
                    <div>
                        <el-descriptions class="mt15" :column="1" :colon="false"
                                         :label-style="labelStyle"
                                         :content-style="contentStyle">
                            <el-descriptions-item label="状态：">
                                <b>
                                    {{ form.status | formatDesc(statusOptions) }}
                                </b>
                            </el-descriptions-item>
                            <el-descriptions-item label="申请时间：">
                                {{ form.createTime | dateCus }}
                            </el-descriptions-item>
                            <el-descriptions-item label="到账时间：" v-if="form.status===3">
                                {{ form.updateTime | dateCus }}
                            </el-descriptions-item>
                            <el-descriptions-item label="转账编号：">
                                {{ form.aliOrderNo }}
                            </el-descriptions-item>
                            <el-descriptions-item label="支付流水号：">
                                {{ form.aliPayId }}
                            </el-descriptions-item>
                            <el-descriptions-item label="支付主体：">
                                上海久柏易游信息科技有限公司
                            </el-descriptions-item>
                            <el-descriptions-item label="司机姓名：">
                                {{ form.driverName }}
                            </el-descriptions-item>
                            <el-descriptions-item label="司机手机：">
                                {{ form.driverMobile }}
                            </el-descriptions-item>
                            <el-descriptions-item label="所属运营商：">
                                {{ form.operatorName }}
                            </el-descriptions-item>
                            <el-descriptions-item label="所属供应商：">
                                {{ form.supplierName }}
                            </el-descriptions-item>
                            <el-descriptions-item label="提现账号：">
                                <div class="alipay-box">
                                    <img class="icon-alipay" src="@/assets/images/alipay.png" alt="">
                                    {{ form.realName || '' }}{{ form.aliPayAccount }}
                                </div>
                            </el-descriptions-item>
                            <el-descriptions-item label="提现金额：">
                                {{ form.accountPrice | currency }} {{ form.accountPrice | chineseCurrency }}
                            </el-descriptions-item>
                            <el-descriptions-item label="提现失败原因：" v-if="form.status===4">
                                {{ form.errorInfo }}
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </div>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="buttons">
                <el-button type="primary" style="width: 110px" plain :loading="loading" v-print="printObj">
                    打印预览
                </el-button>
            </div>
        </el-footer>
    </div>
</template>
<script>

export default {
    data() {
        return {
            statusOptions: [
                {
                    label: "转账中",
                    value: 2
                },
                {
                    label: "已转出",
                    value: 3
                },
                {
                    label: "转账异常",
                    value: 4
                },
            ],
            loading: false,
            form: {},
            labelStyle: {
                width: '130px',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                fontWeight: 'normal',
                color: '#333',
            },
            contentStyle: {
                fontWeight: 'normal',
                color: '#333'
            },
            printObj: {
                id: 'printView',
                popTitle: '提现详情'
            },
            showPrintView: false,
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo
        }
    },
    mounted() {
        this.form = JSON.parse(decodeURIComponent(this.$route.query.info));
    }
}
</script>

<style scoped lang="scss">
.driver-withdrawal-detail {
    .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
    }
}

.driver-withdrawal-content {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 50px;

    .alipay-box {
        display: flex;
        align-items: center;
        position: relative;

        .icon-alipay {
            width: 52px;
            height: 20px;
            margin-right: 20px;
        }

        &:after {
            content: ' ';
            position: absolute;
            left: 62px;
            width: 1px;
            height: 14px;
            background-color: #909399;
        }
    }

    &::v-deep() {
        .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
            padding-bottom: 25px;
        }

        .el-alert--warning {
            background: #E6F7FF;
            border: 1px solid #BAE7FF;
            border-radius: 4px;
            height: 40px;
        }

        .image-cont {
            margin-left: 0;
        }

        .el-icon-warning {
            color: #1890FF;
        }

        .el-alert__title {
            color: rgba(0, 0, 0, .65);
            font-size: 14px;
        }

        img {
            max-width: 104px;
            max-height: 104px;
        }
    }
}

</style>
